.footer {

  .icon {

    margin-right: 1rem;
    width: 20px;

    @include media-breakpoint-up(lg) {

      margin-right: 2rem;
      margin-left: 2rem;
    }
  }
  .list-inline-item:not(:last-child) {

    margin-right: 2rem;
  }
  a {

    color: $secondary;
  }


  @include media-breakpoint-down(md) {

    ul {
      position: relative;
      li.youtube {
        position: absolute;
        right: 50px;
        top: 0;
          margin: 0 !important;
      }
        li.instagram {
            position: absolute;
            right: 0;
            top: 0;
            margin: 0 !important;
        }
    }

  }

  .backToTop {

    .btn {

      padding: 0.75rem !important;
    }
    .icon-arrow {

      display: block;
      width: $grid-gutter-width - 10;
      height: $grid-gutter-width - 10;
      background-image: url('../svg/arrow.svg');
      background-size: contain;
      background-repeat: no-repeat;
      transform: rotate(-90deg);
    }
  }

}
