.kw-module-0310 {

  .embed-responsive {

    &::before {

      padding-top: 100% * 1/1;

      @include media-breakpoint-up(sm) {

        padding-top: 100% * 9/16;
      }

      @include media-breakpoint-up(lg) {

        padding-top: 100% * 1/4;
      }
    }
  }
}

.no-js .kw-module-0310 {

  display: none;
}
