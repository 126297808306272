.my-slick .slick-slide img {
  display: block;
  /* object-fit: contain; */
  object-fit: cover;
  /* object-position: bottom; */
  font-family: 'object-fit: cover;';

  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* padding: 15px; */
}
.modal-content {
  border-radius: 0;
  border: none;
  /* background: rgba(0,0,0,0.35);
  width: 70vw;
  max-width: none; */
  background-color: transparent;
}
.slick-item {
  vertical-align: middle;
}

/* .slick-slide {
    position: relative;
} */
.slick-modal {

  .modal-content {

    //padding-top: 80px;
    padding: 5rem 0 3rem;
    //@include media-breakpoint-up(md) {
    //
    //  padding-top: 0;
    //}
  }
  .slick-arrow {

    padding: 0.75em;

    position: absolute;
    z-index: 5;
    bottom: 0;

    &.c-slick-prev {
      right: 53px;

      img {
        transform: scale(-1, 1);
      }
    }
    &.c-slick-next {
      right: 0;
    }

    @include media-breakpoint-up(md) {

      position: fixed;
      top: 50%;
      right: auto;
      bottom: auto;

      &.c-slick-prev {
        left: 0;
        right: auto;
      }
      &.c-slick-next {
        right: 0;
      }

    }
  }
  .c-close {

    position: absolute;
    right: 0;
    top: 15px;

    @include media-breakpoint-up(md) {

      position: fixed;
      top: 0;
    }
  }

  .slick-dots-cnt {

    transform: translateY(-100%);
    min-height: 32px;

    @include media-breakpoint-up(md) {

      transform: translateY(0);
    }
  }

  .slick-dots {

    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 1;

    margin-left: 10px;

    //@include media-breakpoint-up(md) {}

    li {

      display: inline-block;
      vertical-align: top;

      + li {

        margin-left: 5px;
      }
    }

    button[id*=slick-slide-control] {

      border: 0;
      padding: 0;

      width: 12px;
      height: 12px;
      background: $white;
      text-indent: -9999px;
      cursor: pointer;

      @include hover-focus {

        outline: none;
        background: $secondary;
      }
    }
    .slick-active button[id*=slick-slide-control] {

      background: $secondary;
    }
  }

}



//.slick-dots {
//  margin: 0;
//  padding: 0;
//  list-style: none;
//
//}
//.slick-dots li {
//  margin: 0;
//  padding: 0;
//  display: inline-block;
//}