.cc-window {
  padding: 1em;
  color: $white;
  /*max-width: 24em;*/
  /*flex-direction: column;*/
  font-family: inherit;
  position: fixed;
  /*overflow: hidden;*/
  /*box-sizing: border-box;*/
  /*font-family: Helvetica,Calibri,Arial,sans-serif;*/
  /*font-size: 16px;*/
  /*line-height: 1.5em;*/
  display: flex;
  flex-wrap: nowrap;
  z-index: 9999;
  bottom: 0;
  width: 100%;
  margin: auto;
  right: 0;
  left: 0;

  //box-shadow: 0 0 5px rgba(0,0,0,0.5);

  @include media-breakpoint-down(xs) {

    flex-direction: column;

    .cc-compliance {

      margin-left: auto;
    }
  }
}