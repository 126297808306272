// --------------------------------------------------------------------------------
//  headroom.js
// --------------------------------------------------------------------------------

.hidden-scroll {

  .headroom {

    //right: 17px;
  }
}

.headroom {

  position: fixed;
  //position: absolute;

  z-index: $zindex-sticky;
  top: 0;
  width: 100%;

  will-change: transform;
  transition: transform 200ms ease-out;
}
.headroom--pinned {

  transform: translate3d(0,0,0);
}
.headroom--unpinned {

  transform: translate3d(0,-100%,0);
}

//body:not(.home-page) {
//
//  .headroom--top .navbar {
//
//    //background-color: transparent !important;
//  }
//  .headroom--top .navbar.is-active {
//
//    //background-color: $primary !important;
//  }
//}

.headroom {

  .navbar {

    will-change: background-color;
    transition: background-color 100ms ease-out;
  }
}

.home,
.headroom--not-top.headroom--pinned {

  .navbar {

    background-color: $primary;
  }
}
