//
//
//
#CybotCookiebotDialog,
#CybotCookiebotDialog a,
#CybotCookiebotDialog div,
#CybotCookiebotDialogBodyContentControls,
#CybotCookiebotDialogBodyContentTitle {

    font-family: inherit !important;
}

#CybotCookiebotDialog {

    background: $primary !important;
    color: $white !important;
}

#CybotCookiebotDialogBodyContentTitle,
#CybotCookiebotDialogBodyContentText {

    color: inherit !important;
}

#CybotCookiebotDialogBodyButtons .CybotCookiebotDialogBodyLink,
#CybotCookiebotDialogBodyLevelDetailsButton {

    color: inherit !important;
}




//
//
//
#CybotCookiebotDialog {

    // border: 8px solid #666;
    // border-radius: 2px;
    border: 0 !important;
    border-radius: 0 !important;

    #CybotCookiebotDialogBody {

    }

    #CybotCookiebotDialogPoweredbyLink {

        position: absolute;
        left: -9999px;
        clip: rect(0,0,0,0);
        opacity: 0;
        visibility: hidden;
        display: none;
    }

    #CybotCookiebotDialogBodyContent {

        padding: 2rem;
        padding-bottom: 1rem;
    }

    #CybotCookiebotDialogBodyContentTitle {

        text-align: center;
        //font-size: 44px;
        //font-weight: 100;
        //margin: 0 0 40px 0;
        //color: #111;

        font-size: 32px;
        font-weight: 700;
        margin: 0;
        margin-bottom: 2rem;
    }

    #CybotCookiebotDialogBodyContentTitle::before {

        display: block;
        content: ' ';
        background-image: url('//dachbau-mainz.de/svg/neuhaeuser-logo-200x50.svg');
        background-size: 100% 100px;
        background-position: center top;
        background-repeat: no-repeat;
        height: 120px;
        width: 60%;
        margin: 0 auto;
    }

    #CybotCookiebotDialogBodyContentText {

        text-align: center;
        color: #666;
        line-height: 22px;
    }

    #CybotCookiebotDialogBodyButtons {

        padding: 0;
        margin: 0 12px 18px;
    }

    #CybotCookiebotDialogBodyButtonAccept {

        display: block;
        padding: 2px 5px;
        margin: 16px auto 5px auto;
        max-width: 360px;
        //font-weight: 100;
        text-transform: uppercase;
        //border-radius: 20px;
        text-decoration: none;
        font-size: 20px;
        //color: #4b4b4b;
        background: $secondary;
        line-height: 1.6;

        border: none;
        font-weight: 700;
        color: $primary;
    }

    #CybotCookiebotDialogDetail {

        background: none;
    }

    #CybotCookiebotDialogDetailFooter {

        //display: none;
    }

    @media all and (max-width: 700px) {

        #CybotCookiebotDialogBodyContentTitle {

            font-size: 24px;
        }

        #CybotCookiebotDialogBodyButtonAccept {

            font-size: 16px;
            padding: 6px 3px;
        }
    }
}
