// --------------------------------------------------------------------------------
//  img unveil
//  ...
// --------------------------------------------------------------------------------

.js-unveil {

  .no-js & {
    display: none;
  }

  .js & {

    opacity: 0;
    transition: opacity 500ms ease-out;
  }

  // ?
  //
  //@media print {
  //
  //  display: none !important;
  //}
}