
.no-js .custom-slick-theme {

  //white-space: nowrap;
  overflow-x: scroll;
  display: flex;

  .slick-item {
    width: 100%;
    flex: 0 0 auto;
    + .slick-item {
      margin-left: $grid-gutter-width /2;
    }
  }
}


.custom-slick-theme {

  background: $white;

  .slick-dots {

    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 1;

    position: absolute;
    bottom: 20px;
    left: 20px;

    li {

      display: inline-block;

      + li {

        margin-left: 5px;
      }
    }

    button[id*=slick-slide-control] {

      border: 0;
      padding: 0;

      width: 12px;
      height: 12px;
      background: $white;
      text-indent: -9999px;
      cursor: pointer;

      @include hover-focus {

        outline: none;
        background: $secondary;
      }
    }
    .slick-active button[id*=slick-slide-control] {

      background: $secondary;
    }
  }

  .slick-next,
  .slick-prev {

    //padding: 0.75rem !important;
    //opacity: 0.9;
    //
    //@include hover-focus {
    //
    //  z-index: 2;
    //  opacity: 1;
    //}

    @include custom-btn-with-icon();

    position: absolute;
    z-index: 1;
    bottom: 0;
  }
  .slick-prev {

    right: calc(1.5rem + 26px);
    margin-right: 3px;
    transform: scale(-1, 1);
  }
  .slick-next {

    right: 0;
  }

  .slick-item {

    vertical-align: middle;
  }
}
//
//.X---slick-modal {
//
//  .slick-dots-cnt {
//
//
//    @include media-breakpoint-down(sm) {
//
//      text-align: left;
//      position: relative;
//      top: -35px;
//    }
//  }
//
//  .slick-dots {
//
//    list-style: none;
//    margin: 0;
//    padding: 0;
//    line-height: 1;
//
//    margin: 20px 0 0;
//    //position: absolute;
//    //bottom: 20px;
//    //left: 20px;
//
//    li {
//
//      display: inline-block;
//
//      + li {
//
//        margin-left: 5px;
//      }
//    }
//
//    button[id*=slick-slide-control] {
//
//      border: 0;
//      padding: 0;
//
//      width: 12px;
//      height: 12px;
//      background: $white;
//      text-indent: -9999px;
//      cursor: pointer;
//
//      @include hover-focus {
//
//        outline: none;
//        background: $secondary;
//      }
//    }
//    .slick-active button[id*=slick-slide-control] {
//
//      background: $secondary;
//    }
//  }
//}