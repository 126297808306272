.ofit {

  width: 100%;
  height: 100%;

  &.ofit--cover {

    object-fit: cover;
    font-family: 'object-fit: cover;';
  }
}