.kw-module-0302 {

  .card {

    //background-color: transparent;
  }

  .btn {

    @include custom-btn-with-icon();
  }

  @include media-breakpoint-only(xs) {

    .card-img-top.position-relative {

      position: static !important;

      a {
        &::after {
          content: "";
          display: block;
          //background: rgba(black, 0.2);
          width: 100%;
          position: absolute;
          z-index: 5;
          top: 0;
          bottom: 0;
        }
      }

      a:active {

        position: static;
      }
    }
  }

  .position-right-bottom .btn {

    //transform-origin: right bottom;
    //transform: scale(0.6);
    //
    //@include media-breakpoint-up(sm) {
    //
    //  transform: scale(0.8);
    //}
    //@include media-breakpoint-up(xl) {
    //
    //  transform: scale(1);
    //}
  }


  a {

    img.unveil-loaded {

      transition: all 300ms ease-out;
    }

    @include hover {

      img.unveil-loaded {

        transform: scale(1.1);
        //filter: brightness(1.2);
      }
    }
  }
}