.kw-module-0101 {

  .kw-module-0101__heading {

    //-webkit-user-select: none; /* Safari 3.1+ */
    //-moz-user-select: none; /* Firefox 2+ */
    //-ms-user-select: none; /* IE 10+ */
    //user-select: none; /* Standard syntax */

    @include media-breakpoint-only(xs) {
      padding-top: 50px;
    }
  }
}
