@mixin heading($property) {
  //-webkit-transform: $property;
  //-ms-transform: $property;
  //transform: $property;

  &::after {
    content: "";
    display: block;
    width: 2.5rem;
    height: 3px;
    margin: $property;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    background: $secondary;
  }

}

//.box { @include transform(rotate(30deg)); }

@mixin custom-btn-with-icon() {

  //outline: 1px solid lime;

  position: relative;
  padding: 0.75rem !important;
  opacity: 0.9;

  @include hover-focus {

    z-index: 1;
    opacity: 1;
  }
}
