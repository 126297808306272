.kw-filter-brightness-50 {

	filter: brightness(0.5);

	.no-cssfilters & {

		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba($black, 0.5);
		}
	}
}
.kw-filter-brightness-85 {

  filter: brightness(0.85);

  .no-cssfilters & {

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($black, 0.15);
    }
  }
}
