.kw-module-0302 {

  //@include media-breakpoint-up(md) {}

  .card {

    background-color: transparent;
  }

  @include media-breakpoint-only(xs) {

    [class*=col] + [class*=col] {

      border-top: 1px solid $gray-300;
      padding-top: $grid-gutter-width / 4;
    }

    .card {

      flex-direction: row;
      flex-wrap: nowrap;

      margin-bottom: $grid-gutter-width / 4;
    }
    .card-img-top {

      width: 100% * 80/290;
      flex: 0 0 auto;
    }
    .card-body {

      padding: 0;
      padding-left: $grid-gutter-width / 2;

      align-self: center;
    }
    .card-title {

      margin-bottom: 0;
      font-size: $h4-font-size;
    }

  }

  @include media-breakpoint-up(sm) {

    .position-right-bottom .btn {



      //position: relative;
      //padding: 0.75rem !important;
      //opacity: 0.9;

      //@include hover-focus {
      //
      //  z-index: 1;
      //  opacity: 1;
      //}
    }
  }
}
