// --------------------------------------------------------------------------------
// dev
// --------------------------------------------------------------------------------

.no-js [data-lorem] {
  &::before {
    content: "Lorem ipsum dolor sit amet consectetur adipisicing elit";
    //, consectetur adipisicing elit. Ab adipisci consequatur, maiores perferendis quibusdam sapiente."; // Aperiam corporis deserunt earum eum fuga incidunt, laborum placeat quaerat repellendus similique veniam voluptate voluptatibus.";
  }
}

.develop {

  @for $i from 1 through 6 {
    h#{$i} {
      &::before {
        content: "H" + $i + ": ";
        color: inherit;
      }
    }
  }
  @for $i from 1 through 6 {
    h#{$i} {
      &::after {
        content: attr(class) !important;
        color: inherit;
        padding-left: 0.5em;
        font-size: 50%;
        font-weight: normal;
      }
    }
  }

  .kw-module {

    outline: 1px dotted $yellow;

    &::before {
      content: attr(class);
      font-size: 10px;
      line-height: 1.2;
      padding: 3px;
      position: absolute;
      z-index: 1;
      //top: 0;
      //left: 0;
      display: inline-block;
      background: $yellow;
      color: black;
    }
  }

  //.rte-section {
  //
  //  outline: 1px dotted $success;
  //
  //  &::before {
  //    content: attr(class);
  //    font-size: 10px;
  //    line-height: 1.2;
  //    padding: 3px;
  //    position: absolute;
  //    z-index: 1;
  //    //top: 0;
  //    //left: 0;
  //    display: inline-block;
  //    background: $success;
  //    color: white;
  //  }
  //}
}

// --------------------------------------------------------------------------------

.kw-module {

  //margin: {
  //  top: 10vh;
  //  bottom: 10vh;
  //}
  //padding: {
  //  top: 2rem;
  //  bottom: 2rem;
  //}

  /* Axiomatic CSS and Lobotomized Owls *+* */
  *:first-child {
    //outline: 1px dotted orange;
    margin-top: 0;
  }

  *:last-child {
    //outline: 1px dotted orange;
    margin-bottom: 0;
  }

  .kw-module__header {

    padding: {
      top: 4.5rem;
      bottom: 2.5rem;
    }

    text-align: center;
  }

  .kw-module__heading {

    //		margin: {
    //			top: 2em;
    //			bottom: 2em;
    //		}
    //
    //		;

    //color: $secondary;
    //font-size: $h2-font-size;
    //text-transform: uppercase;

    &::after {
      content: "";
      display: block;
      width: 2.5rem;
      height: 3px;
      margin: auto;
      margin-top: 0.5em;
      margin-bottom: 0.5em;
      background: $secondary;
    }
  }

  .kw-module__lead {
    font-size: $font-size-lg;
    color: $secondary;
  }

  .kw-module__header--left {
    text-align: left;

    .kw-module__heading {
      &::after {
        margin-left: 0;
      }
    }
  }
}
