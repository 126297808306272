.x---kw-module-0301,
.x---kw-module-0303,
.custom-card {

  .card {

    background-color: transparent;
  }

  a {

    img.unveil-loaded {

      transition: all 300ms ease-out;
    }

    @include hover {

      img.unveil-loaded {

        transform: scale(1.1);
        //filter: brightness(1.2);
      }
    }
  }

  .position-right-bottom .btn {

    @include custom-btn-with-icon();

    //position: relative;
    //padding: 0.75rem !important;
    //opacity: 0.9;
    //
    //@include hover-focus {
    //
    //  z-index: 1;
    //  opacity: 1;
    //}

    //transform-origin: right bottom;
    //transform: scale(0.8);
    //
    //@include media-breakpoint-up(xl) {
    //
    //  transform: scale(1);
    //}
  }

  @include media-breakpoint-only(md) {

    .card {

      flex-direction: row;
      flex-wrap: nowrap;
    }
    .card-body {
      width: 50%;
      flex: 0 0 auto;
    }
  }

}