@include media-breakpoint-up(lg) {

  .display-4 {

    font-size: $display4-size * 60/46;
  }
  h1, .h1 {

    font-size: $h1-font-size * 50/32;
  }
  h2, .h2 {

    font-size: $h1-font-size * 32/28;
  }
}