.kw-module-0300 {

  // mixin
  .kw-module-0300__heading {

    @include heading(0);
  }

  .embed-responsive {

    &::before {

      padding-top: 100% * 1/1;

      @include media-breakpoint-up(sm) {

        padding-top: 100% * 9/16;
      }
      @include media-breakpoint-up(lg) {

        padding-top: 100% * 1/1;
      }
    }
  }
}
