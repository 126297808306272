
.embed-responsive-1by1 {

  &::before {

    padding-top: 100% * 1/1;
  }
}

.embed-responsive-3by2 {

  &::before {

    padding-top: 100% * 2/3;
  }
}

.embed-responsive-3by1 {

  &::before {

    padding-top: 100% * 1/3;
  }
}