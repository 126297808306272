
@include media-breakpoint-down(md) {
  .navbar-collapse {
    min-height: calc(100vh - 100px);
  }
}

.navbar-collapse.fade {

  @include media-breakpoint-up(lg) {

    opacity: 1 !important;
  }
}

.navbar-nav {

  @include media-breakpoint-down(md) {
    .nav-item {
      padding: 0.75rem 0;
      + .nav-item {
        border-top: 1px solid rgba($secondary, 0.35);
      }
    }
    .nav-link {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }
}
.nav-link {

  color: $secondary;
  font-size: 1rem;
  font-weight: 500;

  @include hover {

    color: $white;
  }

  .active & {
    color: $white;
  }
}

.navbar-expand-lg .navbar-nav .nav-link {

  padding: {
    right: 0.75rem;
    left: 0.75rem;
  }
}

.navbar {

  //will-change: background-color;
  //transition: background-color 50ms ease-out;

  padding-top: 0;
  padding-bottom: 0;

  .navbar-brand {

    padding-top: 25px;
    padding-bottom: 25px;
  }
}

//.navbar-expand-lg {
//
//  background-color: $primary;
//
//  @include media-breakpoint-up(lg) {
//
//    background-color: transparent;
//  }
//}

// --------------------------------------------------------------------------------
//  html.navbar-open
// --------------------------------------------------------------------------------
.navbar-open {
  &,
  body {
    overflow: hidden;
  }
}

@include media-breakpoint-down(md) {

  .navbar-open .navbar {

    background: $primary;
  }
}
