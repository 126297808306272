.kw-module-0101 {

  height: 50vh;
  max-height: 600px;

  @include media-breakpoint-up(md) {}

  /* IE 11 */
  .position-fixed {
    left: 0;
    right: 0;
    margin: auto;
  }


  .kw-module-0101__heading {

    height: 100%;
    background: url('../svg/header-bg-1920x740.svg');
    background-position: center center;
    //background-repeat: no-repeat;
    background-size: cover;
  }
}
