.kw-module-0100 {

  position: relative;
  overflow: hidden;  

  .kw-module-0100__media,
  .kw-module-0100__overlay {
    height: 100vh;
	max-height: 1080px;
  }

  .kw-module-0100__media {}

  .kw-module-0100__overlay {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
  }

//  .kw-module-0100__overlay * {
//    @include rfs(3.75rem);
//  }

  .kw-module-0100__cta {
    position: absolute;
    z-index: 1;
    bottom: 3vh;
    left: 0;
    width: 100%;
    text-align: center;
  }

  .kw-module-0100__cta a {

    text-decoration: none;
  }

  .arrow {

    display: inline-block;

    &::before {
      content: "";
      display: block;
      width: $grid-gutter-width/2;
      height: $grid-gutter-width/2;
      border: 3px solid $white;
      border-top: 0;
      border-right: 0;
      transform: rotate(-45deg);
    }
  }

  .arrow.animated {
    animation-direction: alternate;
  }

  @include media-breakpoint-up(md) {}




}
